import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      productLine: null,
      productName: null,
      productVersion: null,
      productsSummary: null,
      productsHistory: null,
      productsFilterOptions: null,
      whereUsedFilter: null,
      whereUsedSearchResult: null,
      mediumRiskItems: null,
    },
    mutations: {
      reset(state) {
        state.productLine = null;
        state.productName = null;
        state.productVersion = null;
        state.productsSummary = null;
        state.productsHistory = null;
        state.productsFilterOptions = null;
        state.whereUsedFilter = null;
        state.whereUsedSearchResult = null;
      },
      updateFilters(state, { productLine, productName, productVersion }) {
        state.productLine = productLine;
        state.productName = productName;
        state.productVersion = productVersion;
      },
      updateData(state, summary) {
        state.productsSummary = summary;
      },
      updateHistory(state, history) {
        state.productsHistory = history;
      },
      updateWhereUsedData(state, { whereUsedFilter, whereUsedSearchResult }) {
        state.whereUsedFilter = whereUsedFilter;
        state.whereUsedSearchResult = whereUsedSearchResult;
      },
      updateFilterOptions(state, productsFilterOptions) {
        state.productsFilterOptions = productsFilterOptions;
      },
      updateMediumRiskItems(state, mediumRiskItems) {
        state.mediumRiskItems = mediumRiskItems;
      },
    },
    actions: {
      readData({ state, commit }) {
        const data = {
          product_line: state.productLine,
          product_name: state.productName,
          product_version: state.productVersion,
        };

        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/ui/product/insights',
          data,
        }).then(
          (result) => {
            commit('updateData', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      readItems({ state }) {
        const data = {
          product_line: state.productLine,
          product_name: state.productName,
          product_version: state.productVersion,
        };

        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/ui/product/items',
          data,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      refreshMediumRiskItems({ state, commit }) {
        const data = {
          product_line: state.productLine,
          product_name: state.productName,
          product_version: state.productVersion,
          risk_filter: 2,
        };

        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/ui/product/items',
          data,
        }).then(
          (result) => {
            commit('updateMediumRiskItems', result.data);
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      readHistory({ state, commit }) {
        commit('updateHistory', null);
        const data = {
          product_line: state.productLine,
          product_name: state.productName,
          product_version: state.productVersion,
        };

        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/ui/product/history',
          data,
        }).then(
          (result) => {
            commit('updateHistory', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      async performWhereUsedSearch({ state, commit }, whereUsedFilter) {
        await commit('updateWhereUsedData', { whereUsedFilter, whereUsedSearchResult: null });
        return new Promise((resolve, reject) => {
          if (state.whereUsedFilter == null && state.whereUsedFilter === '') {
            commit('updateWhereUsedData', { whereUsedFilter: state.whereUsedFilter, whereUsedSearchResult: [] });
            resolve();
          }
          const data = {
            product_line: state.productLine,
            product_name: state.productName,
            product_version: state.productVersion,
            search_sku: state.whereUsedFilter,
            max_count: 20,
          };
          this.state.auth.axios_instance({
            method: 'POST',
            url: '/api/ui/product/where_used',
            data,
          }).then(
            (result) => {
              if (state.whereUsedFilter === whereUsedFilter) {
                commit('updateWhereUsedData', { whereUsedFilter: state.whereUsedFilter, whereUsedSearchResult: result.data });
              }

              resolve();
            },
            (error) => {
              promiseErrorHandler(error, reject);
            },
          );
        });
      },
      readFilterOptions({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/ui/product/filters',
        }).then(
          (result) => {
            commit('updateFilterOptions', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },

    },
  }
);
