import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    actions: {
      getWhereUsed(_, {
        searchSku, maxCount, exactMatch, searchByMfrSku, searchByItemSku,
      }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/ui/product/where_used',
          data: {
            search_sku: searchSku,
            max_count: maxCount,
            exact_match: exactMatch,
            search_by_mfr_sku: searchByMfrSku,
            search_by_item_sku: searchByItemSku,
          },
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
