import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      votes: [],
    },
    mutations: {
      updateVotes(state, votes) {
        state.votes = votes;
      },
    },
    actions: {
      refreshVotes({ commit }, { contextType, contextId }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/ui/votes/${contextType}/${contextId}`,
        }).then(
          (result) => {
            commit('updateVotes', result.data);
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      addVote(_, {
        contextType, contextId, secondaryId, vote, voteComments,
      }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `/api/ui/votes/${contextType}/${contextId}`,
          data: {
            secondary_id: secondaryId,
            vote,
            vote_comments: voteComments,
          },
        }).then(
          () => {
            resolve();
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
