import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default {
  namespaced: true,
  actions: {
    getEdaProviderData(_, partNumber) {
      return new Promise((resolve, reject) => {
        this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/ui/eda/${partNumber}`,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        );
      });
    },
  },
};
