import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{ref:"app_bar",staticClass:"header",attrs:{"color":_vm.appBarColor,"elevation":"0","height":"54px"}},[_c('div',{staticClass:"flex justify-center w-full"},[_c(VToolbarTitle,{ref:"page_title_computed",staticClass:"pageTitle heading-2",attrs:{"id":"page_title"}},[_vm._v(_vm._s(_vm.pageTitleComputed)+" ")]),_c(VToolbarTitle,{ref:"page_subtitle",staticClass:"pageSubTitle heading-4"},[_vm._v(_vm._s(_vm.pageSubTitleDate)+" ")])],1),_c(VMenu,{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"menu-activator-container"},[_c(VBtn,_vm._g(_vm._b({staticClass:"menu-activator",attrs:{"id":"logged_as","type":"button","elevation":"0","color":_vm.appBarColor,"title":_vm.org,"light":""}},'v-btn',attrs,false),on),[_c('UserAvatar',{attrs:{"userAvatar":_vm.myAvatar,"smallAvatar":true}}),_c('span',{staticClass:"username-display"},[_vm._v(" "+_vm._s(_vm.fullName)+" "),_c('img',{attrs:{"src":"/images/user-down-icon.svg"}})])],1)],1)]}}])},[_c(VList,[(_vm.isUserManagementAllowed)?_c(VListItem,{ref:"team_action",attrs:{"id":"team"},on:{"click":function($event){return _vm.goToTeam()}}},[_c(VListItemTitle,[_vm._v("Team")])],1):_vm._e(),_c(VListItem,{ref:"logout_action",attrs:{"id":"logout"},on:{"click":function($event){return _vm.logout()}}},[_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }