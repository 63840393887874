/* eslint-disable camelcase */
import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      integrations: null,
      suppliersMap: null,
    },
    mutations: {
      updateIntegrations(state, integrations) {
        state.integrations = integrations;
      },
      updateSuppliersMap(state, suppliersMap) {
        state.suppliersMap = suppliersMap;
      },
    },
    actions: {
      refreshIntegrations({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/ui/integration',
        }).then(
          (result) => {
            commit('updateIntegrations', result.data);
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      refreshSuppliersMap({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/ui/integrations_suppliers',
        }).then(
          (result) => {
            commit('updateSuppliersMap', result.data);
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      // eslint-disable-next-line no-unused-vars
      activateIntegration({ dispatch, commit }, { integrationId, params }) {
        return new Promise((resolve, reject) => {
          this.state.auth.axios_instance({
            method: 'POST',
            url: `/api/ui/integration/${integrationId}`,
            data: params,
          }).then(
            // eslint-disable-next-line no-unused-vars
            (result) => {
              dispatch('refreshIntegrations');
              resolve();
            },
            (error) => {
              promiseErrorHandler(error, reject);
            },
          );
        });
      },
      // eslint-disable-next-line no-unused-vars
      checkIntegration({ dispatch, commit }, integrationId) {
        return new Promise((resolve, reject) => {
          this.state.auth.axios_instance({
            method: 'GET',
            url: `/api/ui/integration/test/${integrationId}`,
          }).then(
            // eslint-disable-next-line no-unused-vars
            (result) => {
              const { healthy } = result.data;
              dispatch('refreshIntegrations');
              resolve(healthy);
            },
            (error) => {
              promiseErrorHandler(error, reject);
            },
          );
        });
      },
      // eslint-disable-next-line no-unused-vars
      deactivateIntegration({ dispatch, commit }, integrationId) {
        return new Promise((resolve, reject) => {
          this.state.auth.axios_instance({
            method: 'DELETE',
            url: `/api/ui/integration/${integrationId}`,
          }).then(
            // eslint-disable-next-line no-unused-vars
            (result) => {
              dispatch('refreshIntegrations');
              resolve();
            },
            (error) => {
              promiseErrorHandler(error, reject);
            },
          );
        });
      },

      searchIntegration(_, { integration_id, query, additional_params = null }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: `/api/ui/api_invoke/${integration_id}`,
          data: { query, ...(additional_params ? { additional_params } : {}) },
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
