import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    actions: {
      // eslint-disable-next-line no-unused-vars
      optimizeBom({ commit }, { workspaceId, data }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: `/api/ui/workspace/edit/${workspaceId}`,
          data,
        }).then(
          (res) => {
            resolve(res.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      // eslint-disable-next-line no-unused-vars
      autoOptimize({ commit }, workspaceId) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/ui/workspace/edit/${workspaceId}`,
        }).then(
          (res) => {
            resolve(res.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
