import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      riskPolicy: null,
    },
    mutations: {
      updateRiskPolicy(state, riskPolicy) {
        state.riskPolicy = riskPolicy;
      },
    },
    actions: {
      refreshRiskPolicy({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/ui/risk_policy',
        }).then(
          (result) => {
            commit('updateRiskPolicy', result.data);
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
      // eslint-disable-next-line no-unused-vars
      saveRiskPolicy({ dispatch, commit }, { policy }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: '/api/ui/risk_policy',
          data: policy,
        }).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
