export default (
  {
    namespaced: true,
    state: {
      mfrInfo: null,
    },
    mutations: {
      updateMfrInfo(state, mfrInfo) {
        state.mfrInfo = mfrInfo;
      },
    },
    actions: {
      refreshMfrInfo({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/ui/mfr_name',
        }).then(
          (result) => {
            commit('updateMfrInfo', result.data);
            resolve();
          },
          (error) => {
            reject(error);
          },
        ));
      },
      testMfrInfo(_, { mfrStr }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: `/api/ui/mfr_name/test_norm/${mfrStr}`,
        }).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          },
        ));
      },
      saveMfrInfo({ commit }, { mfrInfo }) {
        // Create a new FormData instance
        const formData = new FormData();

        // Convert the mfrInfo object to a JSON string
        const file = new Blob([JSON.stringify(mfrInfo)], { type: 'application/json' });

        // Append the file to the formData object
        formData.append('file', file, 'mfrInfo.json');

        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'POST',
          url: '/api/ui/mfr_name/import',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(
          (result) => {
            commit('updateMfrInfo', result.data);
            resolve();
          },
          (error) => {
            reject(error);
          },
        ));
      },
    },
  }
);
