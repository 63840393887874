import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default ({
  namespaced: true,
  actions: {
    getActivityLog(_, { contextId, contextType }) {
      return new Promise((resolve, reject) => this.state.auth.axios_instance({
        method: 'GET',
        url: `/api/ui/activity_log/${contextType}/${contextId}`,
      }).then(
        (res) => {
          resolve(res.data);
        },
        (error) => {
          promiseErrorHandler(error, reject);
        },
      ));
    },
  },
});
