export default (
  {
    namespaced: true,
    state: {
      licensing: null,
    },
    mutations: {
      updateLicensing(state, licensing) {
        state.licensing = licensing;
      },
    },
    actions: {
      refreshLicensing({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/ui/licensing',
        }).then(
          (result) => {
            commit('updateLicensing', result.data);
            resolve();
          },
          (error) => {
            reject(error);
          },
        ));
      },
      // eslint-disable-next-line no-unused-vars
      saveLicensing({ dispatch, commit }, { licensing }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'PUT',
          url: '/api/ui/licensing',
          data: licensing,
        }).then(
          (result) => {
            commit('updateLicensing', result.data);
            resolve();
          },
          (error) => {
            reject(error);
          },
        ));
      },
    },
  }
);
