import promiseErrorHandler from '@/utils/promiseErrorHandlerUtils';

export default (
  {
    namespaced: true,
    state: {
      fieldsMappings: null,
    },
    mutations: {
      updateFieldsMappings(state, fieldsMappings) {
        state.fieldsMappings = fieldsMappings;
      },
    },
    actions: {
      refreshFieldsMappings({ commit }) {
        return new Promise((resolve, reject) => this.state.auth.axios_instance({
          method: 'GET',
          url: '/api/ui/field_mapping',
        }).then(
          (result) => {
            resolve(result);
            commit('updateFieldsMappings', result.data);
          },
          (error) => {
            promiseErrorHandler(error, reject);
          },
        ));
      },
    },
  }
);
